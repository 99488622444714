const projects = document.querySelectorAll(".project");
const projectsCon = document.querySelector(".projects");

const revealSection = (entries, observer) => {
  entries.forEach((entry) => {
    if (!entry.isIntersecting) return;

    entry.target.classList.remove("reveal");
    observer.unobserve(entry.target);
  });
};

const observer = new IntersectionObserver(revealSection, {
  root: null,
  threshold: 0.2,
});

projects.forEach((project) => {
  observer.observe(project);
  project.classList.add("reveal");
});

///////////////////////////////////////////////////////////////////
///////////////////////////////////////////////////////////////////
/// EMAIL
const messageSucces = document.querySelector(".message-succes");
const messageFail = document.querySelector(".message-fail");
const overlay = document.querySelector(".overlay");

document.querySelector("#contact-form").addEventListener("submit", (e) => {
  e.preventDefault();

  sendMail();
});

function sendMail() {
  const params = {
    from_name: document.querySelector(".name").value,
    email_id: document.querySelector(".email").value,
    message: document.querySelector("#message-content").value,
    subject: document.querySelector("#subject").value,
  };

  emailjs.send("service_hif3qw8", "petrov-template1", params).then((res) => {
    document.querySelector(".name").value = "";
    document.querySelector(".email").value = "";
    document.querySelector("#message-content").value = "";
    document.querySelector("#subject").value = "";

    if (res.status === 200) {
      messageSucces.classList.add("show");
      overlay.style.display = "block";

      setTimeout(() => {
        messageSucces.classList.remove("show");
        overlay.style.display = "none";
      }, 2000);
    } else {
      messageFail.classList.add("show");
      overlay.style.display = "block";

      setTimeout(() => {
        messageFail.classList.remove("show");
        overlay.style.display = "none";
      }, 2000);
    }
  });
}
